<template>
	<div>
		<el-button type="text" @click="dialogTableVisible = true">Xem chi tiết</el-button>
		<el-dialog title="Xem chi tiết" :visible.sync="dialogTableVisible">
			<gdkv-detail v-if="category === CATEGORY_THU_NHAP_RSM" :details="details"></gdkv-detail>

			<div v-else>
				<punish :details="details" :category="category"></punish>
			</div>
		</el-dialog>
	</div>
</template>


<script>
import {DETAIL_GDKV, DETAIL_GDVP, DETAIL_TINH, DETAIL_TRUNG_TAM,CATEGORY_THU_NHAP_RSM} from "../../../core/config/payslipConfig";
import GdkvDetail from "./Details/GdkvDetail";
import Punish from "./Details/Punish";

export default {
	name: 'phieu-luong-detail-item',
	components: {GdkvDetail, Punish},
	props: {
		details: {
			type: Array,
			default: [],
		},
		category: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			dialogTableVisible: false,
			DETAIL_GDKV: DETAIL_GDKV,
			DETAIL_GDVP: DETAIL_GDVP,
			DETAIL_TRUNG_TAM: DETAIL_TRUNG_TAM,
			DETAIL_TINH: DETAIL_TINH,
			CATEGORY_THU_NHAP_RSM : CATEGORY_THU_NHAP_RSM
		}
	},
	computed: {
		firstItemType() {
			if (this.details.length > 0) {
				return this.details[0].type;
			} else {
				return 'gdkv_chi_tiet';
			}
		}
	}
};
</script>
<style>
.pointer {
	cursor: pointer;
}
</style>
