<template>
  <tr v-if="space_before_row">
    <td colspan="3" style="width: 500px">&nbsp;</td>
  </tr>
  <tr v-else :class="{className}">
    <td :class="tooltip ? 'pointer': ''" :title="tooltip" :rowSpan="rowSpan" v-if="!hideTitle" style="width: 500px">
      <span :class="title_class_name">{{ title }}<i v-if="tooltip"
                                                    class="fa fa-fw fa-info-circle text-primary"></i></span>
    </td>
    <td><b>{{ value }}</b></td>
    <td>
      <phieu-luong-detail-item
				v-if="details != null && details.length && [CATEGORY_THU_NHAP_RSM,CATEGORY_PHAT,CATEGORY_TRU_KHAC].includes(category)"
				:details="details"
        :category="category"
			>
			</phieu-luong-detail-item>
    </td>
  </tr>
</template>

<script>

import PhieuLuongDetailItem from "./phieu-luong-detail-item";
import {
	CATEGORY_THU_NHAP_RSM,
  CATEGORY_PHAT,
  CATEGORY_TRU_KHAC
} from "../../../core/config/payslipConfig";

export default {
  name: 'phieu-luong-item',
  components: {PhieuLuongDetailItem},
  props: {
    details: {
      type: Array,
      default: () => {
        return []
      },
    },
    category: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    title_class_name: {
      type: String,
      default: '',
    },
    space_before_row: {
      type: Boolean,
      default: false,
    },
    rowSpan: {
      type: Number,
    },
  },
	data(){
		return {
			CATEGORY_THU_NHAP_RSM : CATEGORY_THU_NHAP_RSM,
      CATEGORY_PHAT: CATEGORY_PHAT,
      CATEGORY_TRU_KHAC: CATEGORY_TRU_KHAC
		}
	},
  methods: {},
  mounted() {
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
</style>
