<template>
  <div>
    <el-table :data="details">
      <el-table-column label="Nội dung">
        <template slot-scope="scope">
          {{ scope.row.noi_dung}}
        </template>
      </el-table-column>

      <el-table-column label="Số tiền">
        <template slot-scope="scope">
          {{ scope.row.so_tien }}
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
export default {
  name: "Punish",
  props: {
    details: {
      type: Array,
      default: [],
    },
    category: {
      type: String,
      default: null,
    },
  }
}
</script>

<style scoped>

</style>
